<template>
  <div>
    <!-- modal loading -->
    <b-modal
      id="modal-loading"
      centered
      title="Loading..."
      size="sm"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="text-center p-4">
        <h1>Loading...</h1>
      </div>
    </b-modal>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'onboarding' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-conversions' }">
              Conversions
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              {{ $attrs.pageTitle || "Browse Conversions" }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2 page-title">
          <feather-icon
            icon="FolderIcon"
            size="24"
          />
          {{ $attrs.pageTitle || "All Conversions" }}
        </h2>
      </b-col>

      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
        <b-row>
          <b-col class="text-right">
            <b-button
              class="ml-1"
              variant="outline-primary"
              size="sm"
              @click="refreshConversions"
            >
              <font-awesome-icon :icon="['fas', 'rotate-right']" />
              Refresh Conversions
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              size="sm"
              @click="openTour"
            >
              <font-awesome-icon
                :icon="['fas', 'question']"
                aria-label="Show Tutorial"
              />
              <!-- Help -->
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="mt-1"
        >
          <b-col
            class="mb-1 text-right"
          >
            <b-button
              variant="secondary"
              class="ml-1"
              :style="{ float: 'right' }"
              @click="handleResetDate"
            >
              Reset
            </b-button>
            <flat-pickr
              id="select-date-range"
              v-model="dateRange"
              class="form-control"
              :style="{ maxWidth: '230px', float: 'right' }"
              :config="{ mode: 'range' }"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-overlay
      variant="transparent"
      :show="loadingOverlay"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Please wait...</span>
        </div>
      </template>

      <section v-if="visibleConversions">
        <!-- table row -->
        <b-card>
          <b-row v-if="!userOnboarded">
            <b-col>
              <b-alert
                variant="danger"
                show
              >
                <div class="alert-body">
                  You must finish onboarding before viewing conversions.
                </div>
              </b-alert>

              <b-button
                variant="danger"
                :to="{ name: 'onboarding' }"
              >
                Return to Setup
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="userOnboarded && visibleConversions.length <= 0">
            <b-col>
              <div class="text-center mt-4 mb-1">
                You don't have any <strong>verified</strong> conversions<span v-if="startDate && endDate"> from <strong>{{ startDate }}</strong> to <strong>{{ endDate }}</strong></span>.
              </div>
              <div class="text-center mb-4">
                <b-button
                  variant="primary"
                  :to="{ name: 'browse-conversions-new' }"
                >
                  View New
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="userOnboarded && visibleConversions.length > 0">
            <b-col
              md="2"
              sm="4"
            >
              <b-form-group
                class="mb-0"
                label="Per page"
                label-for="perPageSelect"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                  @change="handlePerPage"
                />
              </b-form-group>
            </b-col>
            <!-- STATUS AND CAMPAIGN FILTER -->
            <!-- <b-col
              md="2"
              class="ml-auto"
            >
              <b-form inline>
                <b-form-select
                  id="statusSelect"
                  v-model="selectedStatus"
                  size="sm"
                  :options="statusOptions"
                />
              </b-form>
            </b-col> -->

            <!-- <b-col
              md="6"
              class="my-1"
            >
              <b-form-group class="mb-0">
                <label
                  class="d-block text-sm-left"
                  for="filterInput"
                >Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col> -->

            <!--
                :per-page="perPage"
                :current-page="currentPage"
            -->
            <b-col cols="12 mt-2">
              <b-table
                hover
                responsive
                :items="visibleConversions"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:head()="data">
                  <span
                    v-b-tooltip.hover
                    :title="data.field.tooltip"
                  >
                    {{ data.label }}
                  </span>
                </template>

                <template #cell(date)="data">
                  <span class="d-block font-weight-bold">
                    {{ data.item.date.date }}
                  </span>
                  <span
                    v-if="data.item.date.ago"
                    class="text-secondary"
                  >{{
                    data.item.date.ago
                  }}</span>
                  <span
                    v-else
                    class="text-secondary"
                  >{{ data.item.date.time }}</span>
                </template>

                <template #cell(lead_info)="data">
                  <b-link
                    :to="showTutorial ? '#' : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }"
                  >
                    <span class="d-block font-weight-bold">
                      <div v-if="data.item.lead_info.full_name">
                        {{ data.item.lead_info.full_name }}
                      </div>
                      <div v-else>
                        {{ data.item.lead_info.first_name }}
                        {{ data.item.lead_info.last_name }}
                      </div>
                    </span>
                  </b-link>
                </template>

                <template #cell(contact_info)="data">
                  <span class="d-block font-weight-bold">
                    {{ data.item.contact_info.email }}
                  </span>
                  <span
                    v-if="data.item.contact_info.phone_number"
                    class="text-secondary"
                  >{{ data.item.contact_info.phone_number }}</span>
                </template>

                <template #cell(verified_date)="data">
                  <span class="d-block font-weight-bold">
                    {{ data.item.verified_date.date }}
                  </span>
                  <span
                    v-if="data.item.verified_date.ago"
                    class="text-secondary"
                  >{{
                    data.item.verified_date.ago
                  }}</span>
                  <span
                    v-else
                    class="text-secondary"
                  >{{
                    data.item.verified_date.time
                  }}</span>
                </template>

                <template #cell(status)="data">
                  <b-badge
                    class="text-capitalize"
                    :class="`status_${data.item.status}`"
                    :variant="statusColor[data.item.status] || 'outline-secondary'"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <template #cell(rating)="data">
                  <b-form-rating
                    v-b-popover.hover.top="
                      data.item.conversion_currency
                        ? `${data.item.conversion_value_pennies * 0.01} ${
                          data.item.conversion_currency
                        }`
                        : ''
                    "
                    :value="
                      calculateStarRating(
                        data.item.conversion_value_pennies,
                        data.item.status
                      )
                    "
                    size="sm"
                    inline
                    no-border
                    readonly
                  />
                </template>

                <template #cell(actions)="data">
                  <div class="actions-bar">
                    <span class="d-inline-block action-button btn-pending">
                      <b-badge
                        v-b-popover.hover.top="'Pending (In Progress)'"
                        :variant="statusColor['pending']"
                        @click="showTutorial ? '' : handleItemApprove(data.item.id, 'pending')"
                      >
                        <font-awesome-icon :icon="['far', 'clock']" />
                      </b-badge>
                    </span>
                    <span class="d-inline-block action-button btn-lost">
                      <b-badge
                        v-b-popover.hover.top="'Closed Lost (Canceled)'"
                        :variant="statusColor['lost']"
                        @click="showTutorial ? '' : handleItemApprove(data.item.id, 'lost')"
                      >
                        <font-awesome-icon :icon="['fas', 'xmark']" />
                      </b-badge>
                    </span>
                    <span class="d-inline-block action-button btn-won">
                      <b-badge
                        v-b-popover.hover.top="'Closed Won (Invoiced)'"
                        :variant="statusColor['won']"
                        @click="showTutorial ? '' : handleItemApprove(data.item.id, 'won')"
                      >
                        <font-awesome-icon :icon="['fas', 'check-double']" />
                      </b-badge>
                    </span>
                    <!-- <span class="d-inline-block action-button btn-notification">
                      <b-badge
                        v-b-popover.hover.top="'Send Notification'"
                        class="text-capitalize"
                        variant="light-info"
                        @click="handleItemNotification(data.item.id)"
                      >
                        <font-awesome-icon :icon="['far', 'envelope']" />
                      </b-badge>
                    </span> -->
                    <span class="d-inline-block action-button btn-viewdetails">
                      <b-badge
                        v-b-popover.hover.top="'View Details'"
                        class="text-capitalize"
                        variant="light-primary"
                        :to="showTutorial ? '#' : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }"
                      >
                        <font-awesome-icon :icon="['far', 'eye']" />
                      </b-badge>
                    </span>

                    <!-- Dropdown -->
                    <b-dropdown
                      variant="link"
                      toggle-class="p-0"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        @click="showTutorial ? '' : handleItemApprove(data.item.id, 'lost')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'xmark']"
                          class="text-danger"
                        />
                        <span class="align-middle ml-50">Closed Lost (Canceled)</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="showTutorial ? '' : handleItemApprove(data.item.id, 'pending')"
                      >
                        <font-awesome-icon
                          :icon="['far', 'clock']"
                          class="text-warning"
                        />
                        <span class="align-middle ml-50">Pending (In Progress)</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="showTutorial ? '' : handleItemApprove(data.item.id, 'won')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'check-double']"
                          class="text-success"
                        />
                        <span class="align-middle ml-50">Closed Won (Invoiced)</span>
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item
                        :to="showTutorial ? '#' : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }"
                      >
                        <font-awesome-icon
                          :icon="['far', 'eye']"
                          class="text-primary"
                        />
                        <span class="align-middle ml-50">View Details</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>

            <b-col md="6">
              <span
                class="text-secondary"
              >Showing 1 to {{ visibleConversions.length }} of
                {{ totalTableRows }} entries</span>
            </b-col>
            <b-col md="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalTableRows"
                :per-page="perPage"
                align="right"
                class="my-0"
                @page-click="handlePaginationClick"
              />
            </b-col>
          </b-row>
        </b-card>
      </section>
      <section v-if="errorMessage || !visibleConversions">
        <b-card>
          <b-row>
            <b-col>
              <b-alert
                v-if="errorMessage"
                variant="danger"
                show
              >
                <div class="alert-body">
                  {{ errorMessage.error || errorMessage.message }}
                </div>
              </b-alert>

              <b-button
                variant="danger"
                :to="{ name: 'onboarding' }"
              >
                Return to Setup
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </section>
    </b-overlay>

    <ApproveConversionModal
      ref="conversionModal"
      :selected-conversion="selectedConversion"
      :selected-modal-action="selectedModalAction"
      :return-to="windowPathname"
      @refresh-event="refreshConversions"
    />
    <app-tour
      name="verifiedConversions"
      :steps="verifiedConversionsTour"
      :callbacks="verifiedConversionTourCallbacks"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormGroup,
  BFormRating,
  BFormSelect, // BForm, BFormInput,
  BLink,
  BOverlay,
  BPagination,
  BTable,
  BSpinner,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import ApproveConversionModal from '../components/conversion-modal/ConversionModal.vue'

/*
 * Create placeholder data for tutorial
 */
const tutorialDataArray = []
for (let i = 0; i < 6; i++) {
  const placeholderData = {
    contact_info: {
      email: 'customer@email.com',
      phone_number: '+14009999999',
    },
    conversion_currency: 'CAD',
    conversion_value_pennies: 20000,
    date: {
      ago: '1 hour ago',
      date: new Date().toDateString(),
      time: '00:00',
    },
    domain: 'testdomain.site',
    form: {
      first_name: 'Customer',
      last_name: 'Name',
      email: 'customer@email.com',
    },
    id: 0,
    is_duplicate: false,
    location: {
      name: 'tutorial-form',
      source: '/conversions',
    },
    lead_info: {
      email: 'customer@email.com',
      first_name: 'Customer',
      full_name: 'Customer Name',
      last_name: 'Name',
    },
    organization: {},
    source: 'D',
    status: 'verified',
    status_id: 1,
    type: 'F',
    verified_date: {
      ago: '1 hour ago',
      date: new Date().toDateString(),
      time: '00:00',
    },
    can_verify: true,
  }
  placeholderData.id += 1
  if (i === 0) {
    placeholderData.status = 'pending'
  } else if (i === 1) {
    placeholderData.status = 'lost'
  } else if (i === 2) {
    placeholderData.status = 'won'
  }
  tutorialDataArray.push(placeholderData)
}

export default {
  components: {
    AppTour,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormGroup,
    BFormRating,
    BFormSelect,
    BLink,
    BOverlay,
    BPagination,
    BTable,
    BSpinner,
    ApproveConversionModal,

    // date picker
    flatPickr,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      loadingOverlay: true,
      errorMessage: null,
      windowPathname: window.location.pathname + window.location.search,

      dateRange: null,
      startDate: null,
      endDate: null,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      perPage: this.$route.query.size ? this.$route.query.size : 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      selectedStatus: null,
      statusOptions: [
        { text: 'Status', value: null },
        { text: 'All', value: 'all' },
        { text: 'New', value: 'new' },
        { text: 'Verified', value: 'verified' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'Pending', value: 'pending' },
        { text: 'Lost', value: 'lost' },
        { text: 'Won', value: 'won' },
      ],
      selectedCampaign: null,
      campaignOptions: [
        { text: 'Campaign', value: null },
        { text: 'All', value: 'all' },
      ],
      // totalRows: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      fields: [
        // { key: 'id', label: 'Id', sortable: true },
        // { key: 'campaign_name', label: 'Campaign', sortable: true },
        {
          key: 'date',
          label: 'Conv. Date',
          tooltip: 'When the conversion action occurred',
        },
        {
          key: 'lead_info',
          label: 'Lead',
          tooltip:
            'The lead information of the visitor who completed a conversion action',
        },
        {
          key: 'contact_info',
          label: 'Contact Info',
          tooltip:
            'The contact information of the visitor who completed a conversion action',
        },
        {
          key: 'verified_date',
          label: 'Verified Date',
          tooltip: 'When the conversion was verified',
        },
        {
          key: 'status',
          label: 'Status',
          tooltip: 'The status of the conversion',
        },
        {
          key: 'rating',
          label: 'Value',
          tooltip: 'The estimated conversion value',
        },
        {
          key: 'actions',
          label: 'Actions',
          tooltip: 'Conversion actions that can be taken',
        },
      ],
      statusColor: {
        verified: 'light-success',
        flagged: 'light-warning',
        spam: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
        pending: 'light-warning',
        lost: 'light-danger',
        won: 'light-success',
      },
      types: {
        F: 'fa-solid fa-table-list',
        P: 'fa-solid fa-phone text-dark-green',
        O: 'fa-solid fa-border-none',
      },
      typeTooltip: {
        F: 'Form',
        P: 'Phone',
        O: 'Other',
      },
      sources: {
        D: 'fa-solid fa-bullseye',
        G: 'fa-brands fa-google',
        U: 'fa-solid fa-question',
      },
      sourceTooltip: {
        D: 'Direct',
        G: 'Google',
        U: 'Unknown',
      },
      // tableItems: [],
      defaultFilterParams: this.$attrs.filterParams,

      selectedConversion: 0,
      selectedModalAction: 'verify',
      verifiedConversionsTour: [
        {
          target:
            'h2.page-title',
          header: {
            title: 'Verified Conversions',
          },
          content:
            "The 'View Verified Conversions' page displays the conversions which have been marked as verified, and are ready for sales teams to contact. Verified conversions should be considered \"open opportunities\" to sales teams. These conversions get their own page to ensure ease in monitoring for progress towards officially becoming a new customer.",
        },
        {
          target: '.actions-bar .btn-pending',
          header: {
            title: 'Pending/In Progress',
          },
          content:
            'Conversions which have been marked as verified, but have not yet been marked as won or lost.',
        },
        {
          target: '.actions-bar .btn-lost',
          header: {
            title: 'Cancelled/Closed Lost',
          },
          content:
            "It's normal that sometimes a good conversion doesn't turn into a signed deal. Sometimes the customer changes their mind, the opportunity is lost to a competitor, or it just wasn't actually a fit in the end. Conversions marked Cancelled/Closed Lost are given a new rating, a lower value, and archived for later reference.",
        },
        {
          target: '.actions-bar .btn-won',
          header: {
            title: 'Closed Won/Revenue-verified',
          },
          content:
            "Conversions that make it all the way to the finish line and become a customer! Here we'll ask for you to confirm the actual revenue that this conversion represents. We will use the Revenue-verified conversion value, and will show the actual value in your reporting and in your advertising network optimization!",
        },
        {
          target: '.actions-bar .btn-viewdetails',
          header: {
            title: 'View Details',
          },
          content: 'This shortcut will bring you to the full details of the conversion in question, where you\'ll be able to see all of the form contents, adjust the form settings, and even see a history of status updates for the conversion.',
        },
      ],
      verifiedConversionTourCallbacks: {
        onStart: this.startTourCallback,
        onFinish: this.finishTourCallback,
        onSkip: this.finishTourCallback,
        onStop: this.finishTourCallback,
      },
      showTutorial: false,
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
    userOnboarded() {
      let userFinishedOnboarding = false
      if (this.userData && this.userData.domains && this.userData.domains.length > 0) {
        userFinishedOnboarding = !!this.userData.domains[0].conversion_values
      }
      return userFinishedOnboarding
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleConversions() {
      const digestData = this.$store.getters['conversions/getVerifiedConversions']
      const tutorialData = tutorialDataArray

      let conversionData = []
      if (this.showTutorial) {
        conversionData = tutorialData
      } else if (digestData) {
        conversionData = digestData.data
      }
      return conversionData
    },
    totalTableRows() {
      const digestData = this.$store.getters['conversions/getVerifiedConversions']
      const linksData = digestData ? digestData.links : 0
      return linksData ? linksData.current_size * linksData.last_page : 0
    },
  },
  watch: {
    $attrs: {
      handler(newAttrs, oldAttrs) {
        // refresh table if viewing new type of conversions
        const newFilterParams = newAttrs && newAttrs.filterParams ? newAttrs.filterParams : {}
        const oldFilterParams = oldAttrs && oldAttrs.filterParams ? oldAttrs.filterParams : {}
        if (newFilterParams.status !== oldFilterParams.status) {
          // reset date range if default
          const currentDate = new Date().toJSON().slice(0, 10)
          this.dateRange = `${currentDate} to ${currentDate}`
          this.startDate = null
          this.endDate = null
          this.currentPage = this.$route.query.page || '1'

          // update filter params and get conversions
          this.defaultFilterParams = newAttrs.filterParams
          this.getConversions(this.defaultFilterParams)
        }
      },
      immediate: true,
    },
    $route(to) {
      // refresh table if page number changes
      if (to.query.page && (to.query.page !== this.currentPage)) {
        this.currentPage = `${to.query.page}`

        const conArgs = {
          ...this.defaultFilterParams,
          page: to.query.page,
        }
        this.getConversions(conArgs)
      }
    },
    dateRange(value) {
      this.handleDateRange(value)
    },
  },
  beforeCreate() {
    if (!this.$route.query.page) {
      // set default page to 1
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, page: 1, size: 10 },
      })
    }
  },
  methods: {
    getConversions(args) {
      // set table page and size
      const conversionArgs = {
        page: this.currentPage,
        size: this.perPage,
        ...args,
      }

      // set date range
      if (this.startDate && this.endDate) {
        conversionArgs.start = this.startDate
        conversionArgs.end = this.endDate
      }

      // get conversions digest
      this.$store
        .dispatch('conversions/fetchVerifiedConversions', conversionArgs)
        .then(response => {
          // if requested page exceeds current pages, send to actual last page
          if (
            response.data.links
            && response.data.links.current_page > response.data.links.last_page
          ) {
            this.currentPage = response.data.links.last_page
            this.$router.replace({
              name: this.$route.name,
              query: { ...this.$route.query, page: response.data.links.last_page },
            })
          }

          this.loadingOverlay = false
          const verifiedTourViewed = localStorage.getItem('tour-verified-conversions')
          if (!verifiedTourViewed) {
            this.openTour()
            localStorage.setItem('tour-verified-conversions', true)
          }
        })
        .catch(error => {
          this.loadingOverlay = false
          this.errorMessage = error
        })
    },
    refreshConversions() {
      // handler for Refresh Conversions button
      this.dateRange = null
      this.startDate = null
      this.endDate = null

      this.getConversions(this.defaultFilterParams)
      this.$bvModal.show('modal-loading') // show loading message
      setTimeout(() => this.$bvModal.hide('modal-loading'), 500)
    },
    handleDateRange(dateValue) {
      // set date range
      if (dateValue) {
        const splitDate = dateValue.split(' ')
        const firstDate = splitDate[0] // earlier date in date range
        const secondDate = splitDate[2] // later date in date range
        if (splitDate.length === 1) {
          // one date only
          this.startDate = firstDate
          this.endDate = firstDate
        } else if (splitDate.length === 3) {
          // two dates with a date range
          this.startDate = firstDate
          this.endDate = secondDate
        }
        this.getConversions(this.defaultFilterParams) // refresh conversions without loading modal
      } else {
        this.startDate = null
        this.endDate = null
      }
    },
    handleResetDate() {
      // reset date range selector
      this.dateRange = ''
      this.startDate = null
      this.endDate = null
      this.getConversions(this.defaultFilterParams) // refresh conversions without loading modal
    },
    openTour() {
      this.$tours.verifiedConversions.start()
    },
    startTourCallback() {
      // show dummy data for conversions table
      console.log('startTourCallback')
      this.showTutorial = true
    },
    finishTourCallback() {
      // hide dummy data for conversions table
      console.log('finishTourCallback')
      this.showTutorial = false
    },

    /* TABLE ACTIONS */

    calculateStarRating(conversionValuePennies, conversionStatus) {
      return this.$refs.conversionModal
        ? this.$refs.conversionModal.calculateStarRating(
          conversionValuePennies,
          conversionStatus,
        )
        : 0
    },
    handleItemView(conversionId) {
      // navigate to the view page for the given resource id
      this.$router.push({ name: 'view-conversion', params: { id: conversionId }, query: { returnTo: this.windowPathname } })
    },
    handleItemApprove(conversionId, modalAction) {
      // valid options: approve, lost, pending, won
      this.selectedModalAction = modalAction || 'verify'
      if (conversionId === this.selectedConversion) {
        this.$refs.conversionModal.launchSelectedModal(
          this.selectedModalAction,
          conversionId,
        )
      } else {
        this.selectedConversion = conversionId
      }
    },
    handleItemReject(conversionId) {
      // get method from conversionModal component
      this.$refs.conversionModal.rejectConversion(conversionId)
    },
    handleItemSpam(conversionId) {
      // get method from conversionModal component
      this.$refs.conversionModal.spamConversion(conversionId)
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1

      this.getConversions(this.defaultFilterParams)
    },
    handlePaginationClick(bvEvent, currentPage) {
      console.log('handlePaginationClick')
      const conArgs = {
        ...this.defaultFilterParams,
        page: currentPage,
      }
      this.getConversions(conArgs)
      this.currentPage = currentPage

      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: currentPage },
      })
      this.windowPathname = window.location.pathname + window.location.search
    },
    handlePerPage(sizeValue) {
      console.log('handlePerPage')
      const conArgs = {
        ...this.defaultFilterParams,
        size: sizeValue,
      }
      this.getConversions(conArgs)

      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, size: sizeValue },
      })
      this.windowPathname = window.location.pathname + window.location.search
    },

    /* MISCELLANEOUS */

    getReadableDate(dateStr) {
      // parse unix date in readable format
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], {
        dateStyle: 'long',
      })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
    getReadableDateOnly(dateStr) {
      const date = new Date(dateStr)
      // Format the date object to a human-readable format
      // Return the human-readable date string
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    showErrorMessage(errorMessage) {
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: errorMessage,
          },
        },
        {
          timeout: false,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.dropdown .btn-secondary {
  border: none;
  background-color: transparent !important;
}
.page-item {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.page-item,
.page-item.active .page-link {
  border-radius: 0.3rem;
}
.text-dark-green {
  color: #00cfe8;
}
body:not(.dark-layout) .text-secondary {
  color: #00000080 !important;
}
.actions-bar {
  min-width: 11rem;
}

/* Style the caret (arrow) inside the select */
.custom-select::after {
  border-color: #7F2BDA !important;
}
.flatpickr-input{
  background-color: #7F2BDA !important;
  color: #fff !important;
  border: 1px solid #7F2BDA !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
}
/*add a caret to the input*/
.flatpickr-input:after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #fff;
}
</style>
