<template>
  <section>
    <!-- modal loading -->
    <b-modal
      id="modal-loading"
      centered
      title="Loading..."
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-card-text>
        <div class="text-center p-4">
          <h1>Loading...</h1>
        </div>
      </b-card-text>
    </b-modal>

    <!-- modal lead remap -->
    <b-modal
      id="modal-remap"
      centered
      title="Form Settings"
      size="lg"
      hide-header
      ok-title="Submit Form Keys"
      cancel-variant="outline-secondary"
      :ok-disabled="!remapFormValid"
      @ok="handleModalRemapSubmit"
    >
      <b-card-text>
        <div v-if="!remapFormData">
          Form data not found.
        </div>

        <!-- <b-row
          v-if="remapFormData"
          class="form-lead-details"
        >
          <b-col md="6">
            <div class="p-1 border-bottom rounded">
              <h5 class="text-capitalize">
                Form Name
              </h5>
              <p class="m-0">
                {{ remapFormData.form_name }}
              </p>
            </div>
          </b-col>

          <b-col md="6">
            <div class="p-1 border-bottom rounded">
              <h5 class="text-capitalize">
                Domain
              </h5>
              <p class="m-0">
                {{ remapFormData.domain }}
              </p>
            </div>
          </b-col>
        </b-row> -->

        <b-row>
          <b-col>
            <b-card class="mb-1">
              <b-row><!-- TODO !formIsRemapped -->
                <b-col>
                  <div class="lead-form-remap">
                    <b-row>
                      <b-col class="text-right">
                        <b-button
                          class="mb-2 ml-1"
                          size="sm"
                          variant="outline-warning"
                          @click="openFormIgnoreModal"
                        >
                          Ignore Form
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col class="text-center">
                        <h4>Form Settings</h4>
                        Match the fields from your lead form to the proper data type.
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        v-for="(formItem) in activeFormItems"
                        :key="formItem.text"
                        md="6"
                      >
                        <b-form-group
                          :label="formItem.text"
                          :label-for="formItem.text"
                        >
                          <template #label>
                            <b-badge
                              href="#"
                              variant="light-danger"
                              @click="handleRemoveField(formItem.text)"
                            >
                              <feather-icon
                                icon="XIcon"
                                size="8"
                              />
                            </b-badge>
                            {{ convertFormName(formItem.text) }}
                          </template>
                          <b-form-select
                            v-model="remappedFormData[formItem.value]"
                            :options="originalFormKeys"
                            :name="formItem.text"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-left">
                        <b-button
                          class="mb-1"
                          variant="outline-primary"
                          @click="openFormFieldModal"
                        >
                          Add Field
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-checkbox
                          id="historicalStatus"
                          v-model="historicalStatus"
                          name="check-button"
                          switch
                        >
                          Apply these settings retroactively to all previous matching forms
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            v-if="remapFormData"
            md="12"
            class="mt-0 mb-0"
          >
            <b-button
              v-b-toggle.form-key-reference
              block
              variant="outline-primary"
            >
              Form Key Reference
            </b-button>
            <b-collapse
              id="form-key-reference"
              :visible="!formIsRemapped"
            >
              <b-card>
                <p>The full form keys from the original data are listed below.</p>
                <ul>
                  <li
                    v-for="(item, index) in remapFormData.form_original_data"
                    :key="index"
                  >
                    <code>{{ index }}</code>
                    {{ item }}
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <!-- modal add field for form settings -->
    <b-modal
      id="modal-add-field"
      centered
      title="Add New Form Field"
      size="sm"
      ok-title="Add Field"
      cancel-variant="outline-secondary"
      :ok-disabled="!newFormField"
      @ok="handleModalAddFieldSubmit"
    >
      <b-card-text>
        <b-row>
          <b-col>
            <b-form-group
              label="Add Form Field"
              label-for="newFormField"
            >
              <b-form-select
                v-model="newFormField"
                :options="remapMenuItems"
                name="newFormField"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <!-- modal verify conversion -->
    <b-modal
      id="modal-approve"
      centered
      title="Verify Conversion"
      size="lg"
      ok-title="Verify Conversion"
      cancel-variant="outline-secondary"
      :ok-disabled="!approveFormValid"
      @ok="handleModalApproveSubmit"
    >
      <b-card-text>
        <div v-if="!remapFormData">
          Form data not found.
        </div>

        <b-row
          v-if="remapFormData"
          class="form-lead-details"
        >
          <b-col
            v-if="remapFormData.form_name"
            md="6"
          >
            <div class="p-1">
              <h5 class="text-capitalize">
                Form Name
              </h5>
              <p class="m-0">
                {{ remapFormData.form_name }}
              </p>
            </div>
          </b-col>

          <b-col md="6">
            <div class="p-1">
              <h5 class="text-capitalize">
                Website Domain
              </h5>
              <p class="m-0">
                {{ remapFormData.domain }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card
              v-if="formIsRemapped && typeIsForm"
              class="mb-1"
            >
              <b-row>
                <b-col>
                  <div class="lead-form-remap">
                    <h4>Lead Form Details</h4>

                    <b-row>
                      <b-col
                        v-for="(item, keyName) in remapFormData.form_remapped_data"
                        :key="keyName"
                        md="6"
                        class="p-1"
                      >
                        <h5>{{ keyName }}</h5>
                        <p>{{ item }}</p>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="mb-1">
              <b-row>
                <b-col>
                  <div class="lead-form-remap">
                    <h4>Conversion Details</h4>

                    <b-row v-if="conversionActionMenuItems.length > 0">
                      <b-col md="6">
                        <b-form-group
                          label="Conversion Action"
                          label-for="conversionAction"
                        >
                          <b-form-select
                            v-model="conversionActionFormData.conversion_action_id"
                            :options="conversionActionMenuItems"
                            name="conversionAction"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="userData">
                      <b-col
                        v-if="userData.domains"
                        md="12"
                      >
                        <conversion-value-rating
                          v-if="userData && userData.domains"
                          :user-conversion-value="estimatedInvoiceAmount"
                          :conversion-status="'verified'"
                          :model-value="conversionActionFormData.conversion_value"
                          @setConversionValue="setConversionValue"
                        />
                      </b-col>
                      <b-col
                        v-if="!userData.currency"
                        md="6"
                      >
                        <b-form-group
                          label="Currency"
                          label-for="currency-select"
                        >
                          <v-currency-select
                            id="currency-select"
                            v-model="selectedCurrency"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="sales-email-form">
                    <div class="mt-2 mb-1">
                      <h4>Send to Another Converifai User (optional)</h4><!-- TODO -->
                      <span class="text-secondary">To invite someone to your Converifai team, <b-link :to="{ name: 'settings-users' }">click here</b-link>.</span>
                    </div>
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Select an existing Converifai user to send"
                          label-for="salesEmailDropdown"
                        >
                          <b-form-select
                            v-model="salesFormData.selected_email"
                            :options="userEmailItems"
                            name="salesEmailDropdown"
                            @change="setSalesEmail"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Or enter an one-time email address below"
                          label-for="salesEmailInput"
                        >
                          <b-form-input
                            v-model="salesFormData.sales_email"
                            name="salesEmailInput"
                            @change="setSalesEmailInput"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <!-- modal won conversion -->
    <b-modal
      id="modal-won"
      centered
      title="Won Conversion"
      size="lg"
      ok-title="Won Conversion"
      cancel-variant="outline-secondary"
      :ok-disabled="!approveFormValid"
      @ok="handleModalWonSubmit"
    >
      <b-card-text>
        <b-row>
          <b-col>

            <b-card class="mb-1">
              <b-row>
                <b-col>
                  <div class="lead-form-remap">
                    <h4>Conversion Details</h4>

                    <b-row>
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        Enter the invoiced amount of this conversion.
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        v-if="conversionActionMenuItems.length > 0"
                        md="6"
                      >
                        <b-form-group
                          label="Conversion Action"
                          label-for="conversionAction"
                        >
                          <b-form-select
                            v-model="conversionActionFormData.conversion_action_id"
                            :options="conversionActionMenuItems"
                            name="conversionAction"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Conversion Value"
                          label-for="conversionValue"
                          description="Minimum value is 0.01"
                        >
                          <b-form-input
                            id="conversionValue"
                            v-model="conversionActionFormData.conversion_value"
                            name="conversionValue"
                            placeholder="1.00"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Currency"
                          label-for="currency-select"
                        >
                          <v-currency-select
                            id="currency-select"
                            v-model="selectedCurrency"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <!-- modal lost conversion -->
    <b-modal
      id="modal-lost"
      centered
      title="Close (Lost) Conversion"
      size="lg"
      ok-title="Close Conversion"
      cancel-variant="outline-secondary"
      :ok-disabled="!approveFormValid"
      @ok="handleModalLostSubmit"
    >
      <b-card-text>
        <b-row>
          <b-col>

            <b-card class="mb-1">
              <b-row>
                <b-col>
                  <div class="lead-form-remap">
                    <h4>Conversion Details</h4>

                    <b-row>
                      <b-col md="12">
                        <conversion-value-rating
                          :user-conversion-value="estimatedInvoiceAmount"
                          :conversion-status="'lost'"
                          :model-value="conversionActionFormData.conversion_value"
                          @setConversionValue="setConversionValue"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <!-- modal pending conversion -->
    <b-modal
      id="modal-pending"
      centered
      title="Pending Conversion"
      size="lg"
      ok-title="Mark As Pending"
      cancel-variant="outline-secondary"
      :ok-disabled="!approveFormValid"
      @ok="handleModalPendingSubmit"
    >
      <b-card-text>
        <b-row>
          <b-col>

            <b-card class="mb-1">
              <b-row>
                <b-col>
                  <div class="lead-form-remap">
                    <h4>Conversion Details</h4>

                    <b-row>
                      <b-col
                        v-if="conversionActionMenuItems.length > 0"
                        md="6"
                      >
                        <b-form-group
                          label="Conversion Action"
                          label-for="conversionAction"
                        >
                          <b-form-select
                            v-model="conversionActionFormData.conversion_action_id"
                            :options="conversionActionMenuItems"
                            name="conversionAction"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <conversion-value-rating
                          :user-conversion-value="estimatedInvoiceAmount"
                          :conversion-status="'pending'"
                          :model-value="conversionActionFormData.conversion_value"
                          @setConversionValue="setConversionValue"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <!-- modal ignored forms -->
    <b-modal
      id="modal-ignored"
      centered
      title="Ignored Forms"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <b-row>
          <b-col>
            <b-card class="mb-1">
              <b-row>
                <b-col>
                  <b-table
                    striped
                    hover
                    show-empty
                    :fields="ignoredFormsTableFields"
                    :items="ignoredForms"
                  >
                    <template #empty="">
                      <div class="text-center">
                        You don't have any ignored forms.
                      </div>
                    </template>

                    <template #cell(form_name)="data">
                      {{ limitString(data.item.form_name, 40) }}
                    </template>

                    <template #cell(actions)="data">
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        @click="restoreIgnoredForm(data.item.id)"
                      >
                        Restore Form
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCollapse,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
  BFormInput,
  BLink,
  BTable,
  VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vCurrencySelect from 'vue-currency-select'
import ConversionValueRating from '@/views/components/conversion-value-rating/ConversionValueRating.vue'

import useJwt from '@/auth/jwt/useJwt'

/**
 * Function to reverse keys and values in an object.
 */
function flipKeyValue(data) {
  return Object.fromEntries(
    Object
      .entries(data)
      .map(([key, value]) => [value, key]),
  )
}

/**
 * Ratios for star ratings.
 */
const conversionRatioTable = {
  lost: [
    { star: 1, ratio: 0 },
    { star: 2, ratio: 0.1 },
    { star: 3, ratio: 0.2 },
    { star: 4, ratio: 0.3 },
    { star: 5, ratio: 0.4 },
  ],
  pending: [
    { star: 0, ratio: 0 },
    { star: 1, ratio: 0.1 },
    { star: 2, ratio: 0.2 },
    { star: 3, ratio: 0.3 },
    { star: 4, ratio: 0.4 },
    { star: 5, ratio: 0.5 },
  ],
}

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BCollapse,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BLink,
    BTable,

    // currency selector
    vCurrencySelect,

    // star rating
    ConversionValueRating,
  },

  props: {
    selectedConversion: {
      type: Number,
      required: true,
      default: 0,
    },
    selectedModalAction: {
      type: String,
      default: 'verify',
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    returnTo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      resource: {},
      remapFormData: {},
      remapMenuItems: [],
      conversionActionMenuItems: [
        {
          text: 'Loading, please wait...',
          value: '',
        },
      ],
      startingFormItems: [
        'first_name',
        'last_name',
        'full_name',
        'email',
        'phone_number',
        'comments',
      ],
      activeFormItems: [],
      originalFormKeys: [],
      historicalStatus: true,

      ignoredFormsTableFields: [
        'domain',
        'form_name',
        'actions',
      ],
      ignoredForms: [],

      newFormField: null,
      remappedFormData: {},
      conversionActionFormData: {},
      formIsRemapped: false,
      starRating: null,

      userEmailItems: [
        { text: 'Select from dropdown', value: null },
      ], // array containing emails of all users attached to agency client
      salesFormData: {
        selected_email: null,
        sales_email: null,
      },
      salesEmails: [],
      agencies: [],
      currentAgency: null,

      reloadAttempt: 0,
      accessGranted: false,
      lastErrorMessage: null,
    }
  },

  computed: {
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
    cmpidExists() {
      return (!!this.resource.cmpid)
    },
    conversionActionsExist() {
      return (this.conversionActionMenuItems && !!this.conversionActionMenuItems.length)
    },
    remappedFormDataExists() {
      return (this.remappedFormData && !!Object.keys(this.remappedFormData).length)
    },
    typeIsForm() {
      return (!!this.resource.form_original_data) // TODO: check if conversion is type F
    },
    selectedCurrency() {
      return this.userData ? this.userData.currency : ''
    },
    remapFormValid() {
      // Validate remap form keys form
      let remapFormValid = false
      if (this.remappedFormDataExists) {
        // if minimum one form key is mapped, matches valid state
        remapFormValid = true
      }
      return remapFormValid
    },
    approveFormValid() {
      // Validate conversion action & approve form
      let approveFormValid = false
      if (
        (!this.conversionActionsExist || this.selectedModalAction === 'lost')
        && this.selectedCurrency
        && this.conversionActionFormData.conversion_value
      ) {
        // if lost or not google conversion, only value is required
        approveFormValid = true
      } else if (
        this.conversionActionsExist
        && this.selectedModalAction !== 'lost'
        && this.selectedCurrency
        && this.conversionActionFormData.conversion_action_id
        && this.conversionActionFormData.conversion_value
      ) {
        // if google conversion, match valid state
        approveFormValid = true
      }
      return approveFormValid
    },
    estimatedInvoiceAmount() {
      let amount = 0
      if (this.userData && this.userData.domains && this.userData.domains.length > 0) {
        amount = this.userData.domains[0].conversion_values.estimated_invoice_amount_pennies // TODO: enable selecting different domain
      }
      return amount
    },
  },

  watch: {
    selectedConversion(newVal) {
      // load conversion data when selectedConversion value changes
      this.loadConversion(newVal)
    },
  },

  created() {
    if (this.selectedConversion) {
      this.loadConversion(this.selectedConversion)
      this.getAgencyClients() // TODO: NOT TRIGGERING?
      this.loadSalesEmails()
    }
  },

  methods: {
    /**
     * Function to limit number of characters in a string.
     */
    limitString(string = '', limit = 0) {
      let newString = string
      if (
        typeof newString === 'object'
        && !Array.isArray(newString)
        && newString !== null
      ) {
        // eslint-disable-next-line no-param-reassign
        newString = JSON.stringify(newString)
      }
      newString = String(newString).substring(0, limit)
      if (newString.length > limit) newString = `${newString}…`
      return newString
    },

    /**
     * Function to convert a form value ("first_name") to a readable format ("First Name").
     */
    convertFormName(formValue) {
      const splitWords = formValue.split('_')
      for (let i = 0; i < splitWords.length; i++) {
        splitWords[i] = splitWords[i].charAt(0).toUpperCase() + splitWords[i].slice(1)
      }
      return splitWords.join(' ')
    },

    /**
     * Function to reset all forms in modals.
     */
    resetForms() {
      this.remappedFormData = {}
      this.conversionActionFormData = {}
    },

    /**
     * Function to refresh page.
     */
    refreshPage(options) {
      const funcOptions = {
        redirectToDigest: 'false',
        digestType: 'new',
        ...options,
      }

      if (
        funcOptions.redirectToDigest
        && this.returnTo
      ) {
        this.$router.push(this.returnTo)
      } else if (
        funcOptions.redirectToDigest
        && funcOptions.digestType === 'new'
        && this.$route.name !== 'browse-conversions-new'
      ) {
        // if new conversion, redirect to new conversions digest
        this.$router.push({ name: 'browse-conversions-new' })
      } else if (
        funcOptions.redirectToDigest
        && funcOptions.digestType === 'verified'
        && this.$route.name !== 'browse-conversions-verified'
      ) {
        // if verified conversion, redirect to verified conversions digest
        this.$router.push({ name: 'browse-conversions-verified' })
      } else {
        // emit event to refresh data on parent component
        this.$emit('refresh-event')
      }
    },

    /** =========================
     *     GET CONVERSION DATA
     *  ========================= */

    /**
     * Function to load conversion data, then pass to getConversionActionIds.
     */
    loadConversion(conversionId) {
      if (conversionId) {
        this.$bvModal.show('modal-loading') // show loading
        this.resetForms()

        this.getAgencyClients()
        this.loadSalesEmails()

        // fetch conversion data
        useJwt
          .getConversion(conversionId)
          .then(response => {
            console.log('getConversion', response.data.data)
            const resourceData = response.data.data
            this.resource = resourceData
            this.accessGranted = true
            this.lastErrorMessage = null
          })
          .catch(error => {
            console.log(error.response.data)
            this.showErrorMessage(error.response.data.error)
            this.accessGranted = false
            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
            })
          })

        // fetch conversion action ids next
        this.getConversionActionIds(conversionId)
      } else {
        console.log('ERROR: missing conversion id')
      }
    },

    /**
     * Function to load conversion actions data, then pass to getFormRemapMenu.
     * Formats conversion actions for use in the form. Actions only exist for Google conversions.
     */
    getConversionActionIds(conversionId) {
      // fetch conversion action ids
      if (this.cmpidExists) {
        useJwt
          .getConversionAction(conversionId)
          .then(response => {
            console.log('getConversionAction', response.data.data) // DELETE LATER
            const resourceData = response.data.data
            this.conversionActionMenuItems = []
            resourceData.forEach(item => {
              this.conversionActionMenuItems.push({ value: item.id, text: item.name })
            })
          })
          .catch(error => {
            console.log('getConversionAction ERROR', error.response.data)
            this.conversionActionMenuItems = []
          })

        // fetch conversion remap menu
        this.getFormRemapMenu(conversionId)
      } else {
        this.conversionActionMenuItems = []

        // fetch conversion remap menu
        this.getFormRemapMenu(conversionId)
      }
    },

    /**
     * Function to load remap menu data, then launches modal (either 'verify' or 'remap').
     * Formats form keys and target keys for use in the form.
     */
    getFormRemapMenu(conversionId) {
      // fetch conversion remap menu
      useJwt
        .getRemapMenu(conversionId)
        .then(response => {
          console.log('getRemapMenu', response.data.data) // DELETE LATER
          const resourceData = response.data.data
          this.remapFormData = resourceData

          // convert original form keys for use in form
          if (resourceData.form_original_data) {
            // populate array of all original form keys
            this.originalFormKeys = [] // reset form keys
            const formDataArray = Object.entries(resourceData.form_original_data)
            formDataArray.forEach(item => {
              this.originalFormKeys.push({ value: item[0], text: this.limitString(`${item[0]} : ${item[1]}`, 40) })
            })
          }

          // if target keys exist, convert for use in form
          if (resourceData.target_keys && resourceData.target_keys.length > 0) {
            const formItems = resourceData.target_keys
            // populate array of all key items
            this.remapMenuItems = [] // reset menu items
            formItems.forEach(item => {
              this.remapMenuItems.push({ value: item.id, text: item.name })
            })
            // if remapped data exists, change starting form items
            if (resourceData.form_remapped_data) {
              this.startingFormItems = Object.keys(resourceData.form_remapped_data)
            }
            // populate array of active key items
            this.activeFormItems = [] // reset form items
            this.startingFormItems.forEach(item => {
              const matchingFormItem = formItems.find(element => element.name === item)
              this.activeFormItems.push({ value: matchingFormItem.id, text: matchingFormItem.name })
            })
          }

          this.$nextTick(() => {
            this.$bvModal.hide('modal-loading') // hide loading
          })

          // if remapped data exists, mark form as remapped
          // TODO: check if conversion is type F
          if (resourceData.form_remapped_data || !this.typeIsForm) {
            this.formIsRemapped = true
            this.remappedFormData = {}
            resourceData.form_key_map_lookup.forEach(item => {
              this.remappedFormData[item.target_key_id] = item.original_key
            })

            // open conversion approval form modal (or remap form modal)
            if (!this.viewOnly) {
              this.launchSelectedModal(this.selectedModalAction, conversionId)
            }
          } else if (!this.viewOnly) {
            // if form isn't remapped, open remap lead form modal
            this.openFormRemapModal()
          }
        })
        .catch(error => {
          console.log(error)
          this.showErrorMessage(error.response.data.error)
        })
    },

    /** =====================
     *     REMAP LEAD FORM
     *  ===================== */

    openFormRemapModal() {
      // open form remap modal
      this.$bvModal.show('modal-remap')
    },
    openFormFieldModal() {
      // open add form field modal
      this.$bvModal.show('modal-add-field')
    },
    remapFormKeys(conversionId) {
      // Creates a form key remapping for the campaign/form name combination.
      const formattedData = flipKeyValue(this.remappedFormData)
      const submitData = {
        ...formattedData,
      }
      const historical = this.historicalStatus // check if remap should be retroactively applied

      this.$bvModal.show('modal-loading') // show loading

      if (conversionId && formattedData) {
        useJwt
          .postFormRemap(conversionId, historical, submitData)
          .then(response => {
            const responseData = response
            console.log('postFormRemap', responseData)

            // confirm message
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Form Settings Done',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'The form keys for your lead have been mapped.',
              },
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.refreshPage()
            })
          })
          .catch(error => {
            console.log(error.response.data)
            this.showErrorMessage(error.response.data.error)
            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
            })
          })
      }
    },
    openFormIgnoreModal() {
      // confirm alert
      this.$swal({
        title: 'Ignore?',
        text: 'Conversions from this form will be ignored. We will no longer save incoming data from this form.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ignore it',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$bvModal.show('modal-loading')

          const submitData = {
            domain: this.remapFormData.domain,
            form_name: this.remapFormData.form_name,
          }

          useJwt
            .ignoreFormRemap(submitData)
            .then(() => {
              // confirm message
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Form Ignored',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'This form will be ignored. We will no longer save incoming data from this form.',
                },
              })
              this.$nextTick(() => {
                this.$bvModal.hide('modal-loading') // hide loading
                this.$bvModal.hide('modal-remap') // hide remap modal
              })
              this.refreshPage()
            })
            .catch(error => {
              console.log(error.response.data)
              this.showErrorMessage(error.response.data.error)

              this.$nextTick(() => {
                this.$bvModal.hide('modal-loading') // hide loading
                this.$bvModal.hide('modal-remap') // hide remap modal
              })
            })
        }
      })
    },
    openIgnoredFormsModal() {
      // get list of ignored forms
      useJwt
        .getIgnoredForms()
        .then(response => {
          const responseData = response.data.data
          console.log('getIgnoredForms', responseData)
          this.ignoredForms = responseData

          // launch modal for ignored forms
          this.$bvModal.show('modal-ignored')
        })
        .catch(error => {
          console.log(error.response.data)
          this.showErrorMessage(error.response.data.error)
        })
    },
    restoreIgnoredForm(formId) {
      // restore ignored form
      // confirm alert
      this.$swal({
        title: 'Restore?',
        text: 'This form will show in your conversions again.',
        showCancelButton: true,
        confirmButtonText: 'Yes, restore it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$bvModal.show('modal-loading')

          useJwt
            .deleteFormRemap(formId)
            .then(response => {
              const responseData = response
              console.log('deleteFormRemap', responseData)

              // confirm message
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Form No Longer Ignored',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'This form has been restored.',
                },
              })
              this.$nextTick(() => {
                this.$bvModal.hide('modal-loading') // hide loading
                this.$bvModal.hide('modal-ignored') // hide ignore forms
              })
            })
            .catch(error => {
              console.log(error.response.data)
              this.showErrorMessage(error.response.data.error)

              this.$nextTick(() => {
                this.$bvModal.hide('modal-loading') // hide loading
              })
            })
        }
      })
    },

    /** =========================
     *     APPROVE CONVERSIONS
     *  ========================= */

    openApproveConversionForm() {
      this.resetForms()
      if (this.accessGranted) {
        if (this.formIsRemapped) {
          // if form is remapped, open conversional approval modal
          this.$bvModal.show('modal-approve')
        } else {
          // if form is not remapped, open form remap modal
          this.openFormRemapModal()
        }
      } else {
        this.showErrorMessage(this.lastErrorMessage)
      }
    },
    openWonConversionForm() {
      this.resetForms()
      this.$bvModal.show('modal-won')
    },
    openLostConversionForm() {
      this.resetForms()
      this.$bvModal.show('modal-lost')
    },
    openPendingConversionForm() {
      this.resetForms()
      this.$bvModal.show('modal-pending')
    },
    approveConversion(conversionId) {
      this.reloadAttempt += 1
      if (conversionId) {
        const submitData = {
          ...this.conversionActionFormData,
        }
        if (this.selectedCurrency) submitData.currency = this.selectedCurrency

        this.$bvModal.show('modal-loading')

        useJwt
          .setConversionVerified(conversionId, submitData)
          .then(response => {
            const responseData = response
            console.log('setConversionVerified', responseData)

            // confirm message
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Conversion Verified',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your conversion has been verified.',
              },
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-approve') // hide approve modal
            })
            if (this.$route.name === 'approve-conversion') {
              this.$router.push({
                name: 'view-conversion',
                params: { id: conversionId },
              })
            }
            this.refreshPage({ redirectToDigest: true })
          })
          .catch(error => {
            console.log(error.response.data)
            this.showErrorMessage(error.response.data.error)

            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-approve') // hide remap modal
            })
          })
      } else if (this.reloadAttempt < 3) {
        setTimeout(() => {
          this.approveConversion(this.resource.id)
        }, 500)
      }
    },
    rejectConversion(conversionId) {
      // confirm alert
      this.$swal({
        title: 'Reject?',
        text: 'This conversion should not be used in performance reporting and will be marked as "rejected".',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, reject it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt
            .setConversionRejected(conversionId)
            .then(response => {
              const responseData = response
              console.log('setConversionRejected', responseData.data)

              // confirm message
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Conversion Rejected',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Your conversion has been rejected.',
                },
              })
              this.refreshPage({ redirectToDigest: true })
            })
            .catch(error => {
              console.log(error.response.data)
              this.showErrorMessage(error.response.data.error)
            })
        }
      })
    },
    spamConversion(conversionId) {
      // confirm alert
      this.$swal({
        title: 'Mark as Spam?',
        text: 'This conversion will be marked as spam.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, it\'s spam',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt
            .setConversionSpam(conversionId)
            .then(response => {
              const responseData = response
              console.log('setConversionSpam', responseData)

              // confirm message
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Conversion Marked as Spam',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Your conversion has been marked as spam.',
                },
              })
              this.refreshPage({ redirectToDigest: true })
            })
            .catch(error => {
              console.log(error.response.data)
              this.showErrorMessage(error.response.data.error)
            })
        }
      })
    },
    wonConversion(conversionId) {
      this.reloadAttempt += 1
      if (conversionId) {
        const submitData = {
          ...this.conversionActionFormData,
        }
        if (this.selectedCurrency) submitData.currency = this.selectedCurrency

        this.$bvModal.show('modal-loading')

        useJwt
          .setConversionWon(conversionId, submitData)
          .then(response => {
            const responseData = response
            console.log('setConversionWon', responseData)

            // confirm message
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Conversion Won',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your conversion has been closed.',
              },
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-won') // hide approve modal
            })
            if (this.$route.name === 'approve-conversion') {
              this.$router.push({
                name: 'view-conversion',
                params: { id: conversionId },
              })
            }
            this.refreshPage({ redirectToDigest: true, digestType: 'verified' })
          })
          .catch(error => {
            console.log(error.response.data)
            this.showErrorMessage(error.response.data.error)

            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-won') // hide remap modal
            })
          })
      } else if (this.reloadAttempt < 3) {
        setTimeout(() => {
          this.wonConversion(this.resource.id)
        }, 500)
      }
    },
    lostConversion(conversionId) {
      this.reloadAttempt += 1
      console.log('reloadAttempt', this.reloadAttempt)
      if (conversionId) {
        const submitData = {
          ...this.conversionActionFormData,
        }
        if (this.selectedCurrency) submitData.currency = this.selectedCurrency

        this.$bvModal.show('modal-loading')

        useJwt
          .setConversionLost(conversionId, submitData)
          .then(response => {
            const responseData = response
            console.log('setConversionLost', responseData)

            // confirm message
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Conversion Lost',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your conversion has been closed.',
              },
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-lost') // hide approve modal
            })
            if (this.$route.name === 'approve-conversion') {
              this.$router.push({
                name: 'view-conversion',
                params: { id: conversionId },
              })
            }
            this.refreshPage({ redirectToDigest: true, digestType: 'verified' })
          })
          .catch(error => {
            console.log(error.response.data)
            this.showErrorMessage(error.response.data.error)

            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-lost') // hide remap modal
            })
          })
      } else if (this.reloadAttempt < 3) {
        setTimeout(() => {
          this.lostConversion(this.resource.id)
        }, 500)
      } else {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-lost') // hide approve modal
        })
        this.showErrorMessage("You don't have access to this conversion.")
      }
    },
    pendingConversion(conversionId) {
      this.reloadAttempt += 1
      if (conversionId) {
        const submitData = {
          ...this.conversionActionFormData,
        }
        if (this.selectedCurrency) submitData.currency = this.selectedCurrency

        this.$bvModal.show('modal-loading')

        useJwt
          .setConversionPending(conversionId, submitData)
          .then(response => {
            const responseData = response
            console.log('setConversionPending', responseData)

            // confirm message
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Conversion Pending',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your conversion has been marked as pending.',
              },
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-pending') // hide approve modal
            })
            if (this.$route.name === 'approve-conversion') {
              this.$router.push({
                name: 'view-conversion',
                params: { id: conversionId },
              })
            }
            this.refreshPage({ redirectToDigest: true, digestType: 'verified' })
          })
          .catch(error => {
            console.log(error.response.data)
            this.showErrorMessage(error.response.data.error)

            this.$nextTick(() => {
              this.$bvModal.hide('modal-loading') // hide loading
              this.$bvModal.hide('modal-pending') // hide remap modal
            })
          })
      } else if (this.reloadAttempt < 3) {
        setTimeout(() => {
          this.pendingConversion(this.resource.id)
        }, 500)
      }
    },
    calculateConversionValue(starRating) {
      // Convert star rating to conversion value for conversionActionFormData
      const userConversionValue = this.estimatedInvoiceAmount
      const conversionStatus = this.resource.status === 'lost' ? 'lost' : 'pending'
      let calculatedValue = 0
      if (conversionStatus && starRating && userConversionValue) {
        const mathRatio = conversionRatioTable[conversionStatus].find(item => starRating === item.star) || 0
        calculatedValue = userConversionValue * mathRatio.ratio * 0.01
      }
      this.$set(this.conversionActionFormData, 'conversion_value', calculatedValue)
      return calculatedValue
    },
    calculateStarRating(conversionValue, currentConversionStatus) {
      // Convert conversion value to star rating out of 5
      let calculatedRating = 0
      if (conversionValue && currentConversionStatus) {
        const userConversionValue = this.estimatedInvoiceAmount
        const conversionStatus = currentConversionStatus === 'lost' ? 'lost' : 'pending'
        if (currentConversionStatus === 'won') {
          calculatedRating = 5 // all 'won' conversions should have full stars
        } else if (conversionValue && conversionStatus && userConversionValue) {
          const mathRatio = parseFloat((conversionValue / userConversionValue).toFixed(1))
          const matchingEntry = conversionRatioTable[conversionStatus].find(item => mathRatio === item.ratio)
          if (matchingEntry) {
            calculatedRating = matchingEntry.star
          }
        }
      }
      return calculatedRating
    },
    setConversionValue(value) {
      if (value) {
        this.$set(this.conversionActionFormData, 'conversion_value', value)
      }
    },

    /** ===================
     *     SEND TO SALES
     *  =================== */

    getAgencyClients() {
      // get agency clients
      useJwt.getAgencyClients()
        .then(response => {
          const agencyData = response.data.data
          this.agencies = agencyData
          if (agencyData.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.currentAgency = agencyData[0] // TODO: SELECT OTHER AGENCY
            console.log('getAgencyClients', this.currentAgency)
            /* this.setAgency(this.currentAgency)

            if (this.currentAgency.google_customer_id) {
              this.setCustomerId(this.currentAgency.google_customer_id)
            } */

            this.getAgencyClientUsers(this.currentAgency.id)
          }
        })
        .catch(error => {
          console.log('getAgencyClients ERROR', error.response)
        })
    },
    getAgencyClientUsers(agencyClientId) {
      // fetch digest of users attached to agency client
      useJwt
        .getAgencyClientUsers(agencyClientId)
        .then(response => {
          const responseData = response.data.data
          console.log('getAgencyClientUsers', responseData) // DELETE LATER
          if (responseData.length > 0) {
            this.userEmailItems = [{ text: 'Select from dropdown', value: null }] // reset email list
            const emailList = this.userEmailItems || []
            responseData.forEach(user => {
              emailList.push({ text: user.name, value: user.email })
            })
            this.userEmailItems = emailList
          }
        })
        .catch(error => {
          console.log('getAgencyClientUsers ERROR', error.response.data)
        })
    },
    setSalesEmail(value) {
      console.log('setSalesEmail', value)
      this.$set(this.salesFormData, 'sales_email', value)
      this.$set(this.conversionActionFormData, 'sales_email', value)
    },
    setSalesEmailInput(value) {
      console.log('setSalesEmailInput', value)
      this.$set(this.salesFormData, 'selected_email', value)
      this.$set(this.conversionActionFormData, 'sales_email', value)
    },
    loadSalesEmails() {
      // fetch user's sales team emails
      useJwt
        .getSalesEmails()
        .then(response => {
          const responseData = response.data.data
          console.log('getSalesEmails', responseData) // DELETE LATER
          this.salesEmails = responseData
        })
        .catch(error => {
          console.log('getSalesEmails ERROR', error.response.data)
          this.showErrorMessage(error.response.data.error)
        })
    },

    /** ====================
     *     MODAL HANDLERS
     *  ==================== */

    launchSelectedModal(modalAction, conversionId) {
      if (this.accessGranted) {
        console.log('launchSelectedModal', modalAction, conversionId) // DELETE LATER
        // launch the appropriate modal depending on the specified action
        if (modalAction === 'verify') {
          this.openApproveConversionForm()
        } else if (modalAction === 'remap') {
          this.openFormRemapModal()
        } else if (modalAction === 'lost') {
          this.openLostConversionForm(conversionId)
        } else if (modalAction === 'pending') {
          this.openPendingConversionForm(conversionId)
        } else if (modalAction === 'won') {
          this.openWonConversionForm(conversionId)
        }
      } else {
        this.showErrorMessage(this.lastErrorMessage)
      }
    },
    handleModalRemapSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()

      // Trigger submit handler
      if (this.remapFormValid) {
        const conversionId = this.resource.id
        this.remapFormKeys(conversionId)
      }
    },
    handleModalAddFieldSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()

      // Check if field already exists
      const fieldExists = this.activeFormItems.find(element => element.value === this.newFormField)

      // Trigger submit handler
      if (!fieldExists && this.newFormField) {
        // populate array of active key items
        const matchingFormItem = this.remapMenuItems.find(element => element.value === this.newFormField)
        this.activeFormItems.push(matchingFormItem)
        this.newFormField = null
        this.$nextTick(() => {
          this.$bvModal.hide('modal-add-field')
        })
      } else {
        this.showErrorMessage('Form field already exists.')
      }
    },
    handleRemoveField(fieldName) {
      /* Remove a field from form settings */
      // confirm alert
      this.$swal({
        title: 'Delete?',
        text: `The "${fieldName}" field will be deleted from the form.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // remove from remappedFormData
          const matchingFieldItem = this.activeFormItems.find(element => element.text === fieldName)
          if (this.remappedFormData[matchingFieldItem.value]) {
            delete this.remappedFormData[matchingFieldItem.value]
          }

          // remove from activeFormItems
          const fieldIndex = this.activeFormItems.findIndex(element => element.text === fieldName)
          this.$delete(this.activeFormItems, fieldIndex)
        }
      })
    },
    handleModalApproveSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.reloadAttempt = 0

      // Trigger submit handler
      if (this.approveFormValid) {
        const conversionId = this.resource.id
        this.approveConversion(conversionId)
      }
    },
    handleModalWonSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.reloadAttempt = 0

      // Trigger submit handler
      if (this.approveFormValid) {
        const conversionId = this.resource.id
        this.wonConversion(conversionId)
      }
    },
    handleModalLostSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.reloadAttempt = 0

      // Trigger submit handler
      if (this.approveFormValid) {
        const conversionId = this.resource.id
        this.lostConversion(conversionId)
      }
    },
    handleModalPendingSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.reloadAttempt = 0

      // Trigger submit handler
      if (this.approveFormValid) {
        const conversionId = this.resource.id
        this.pendingConversion(conversionId)
      }
    },

    showErrorMessage(errorMessage) {
      this.lastErrorMessage = errorMessage
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: errorMessage,
          },
        },
        {
          timeout: false,
        },
      )
    },
  },
}
</script>
